const rawSchools = [  
  'Georgia Institute of Technology',
  'Augusta University',
  'Stony Brook University',
  'Duke University',
  'University of North Georgia',
  'Harvey Mudd College',
  'Princeton University',
  'Florida Institute of Technology',
  'University of North Carolina at Charlotte',
  'Texas A&M University',
  'Xavier University of Louisiana',
  'Imperial College London',
  'University of Florida',
  'University of Virginia',
  'Florida International University',
  'Virginia Polytechnic Institute and State University',
  'Indian Institute of Technology,Kharagpur',
  'Georgia State University',
  'University of Central Florida',
  'Georgia Southern University',
  'University of Texas at Austin',
  'Auburn University',
  'University of Texas at Dallas',
  'Brigham Young University',
  'University of Illinois at Urbana–Champaign',
  'Rutgers University',
  'University of Michigan',
  'North Carolina State University',
  'Dartmouth College',
  'Emory University',
  'Clemson University',
  'University of Georgia',
  'Reva University',
  'University of California, San Diego',
  'University of Mississippi',
  'University at Buffalo',
  'University of California, Berkeley',
  'Carleton College',
  'George Mason University',
  'Carnegie Mellon University',
  'University of Washington',
  'University of Alabama - Tuscaloosa',
  'New York University',
  'Rochester Institute of Technology',
  'University of California, Irvine',
  'Vanderbilt University',
  'Nova Southeastern University',
  'University of Miami',
  'University of Waterloo',
  'Monash University',
  'Mount Holyoke College',
  'Carleton University',
  'University of Toronto',
  'University of Chicago',
  'Georgia Gwinnett College',
  'Massachusetts Institute of Technology',
  'Mercer University ',
  'California State University, Fullerton',
  'Western University',
  'Cornell University',
  'Sir M Visvesvaraya Institute of Technology',
  'Technical University of Munich, Germany',
  'York University',
  'Howard University',
  'Other',
  'Brooklyn College',
  'California Polytechnic, SLO',
  'Cal Poly San Luis Obispo',
  'California Institute of Technology',
  'Rice University',
  'University of Cincinnati',
  'Indian Institute of Information Technology, Sri City',
  'New Jersey Institute of Technology',
  'Mission College',
  'Arizona State University',
  'Western Governors University',
  'Yale University',
  'De Anza Community College',
  'University of Southern California',
  'Guru Gobind Singh Indraprastha University',
  'Florida Atlantic University',
  'University of Minnesota',
  'University of Pennsylvania',
  'University of California, Davis',
  'Hunter College',
  'Univerisity of Limerick',
  'Colorado School of Mines',
  'APJ Abdul Kalam Technical University',
  'Fordham University',
  'University of South Florida',
  'University Of Windsor',
  'University of California, Los Angeles',
  'University of Hawaii at Manoa',
  'Purdue University',
  'Lehman College',
  'Box hill Institute',
  'Online Micro Masters Program at Chalmers University of Technology',
  'Reed College',
  'University of Delaware',
  'Grand Canyon University',
  'City College of New York',
  'Florida A&M University ',
  'Stevens Institute of Technology',
  'University of Massachusetts Amherst',
  'Drexel University',
  'Northeastern University',
  'Myanmar Institute of Theology (B.A/ B.Sc program)',
  'Georgetown University',
  'Universidad Anáhuac',
  'Stanford University',
  'University of British Columbia ',
  'University of California, Santa Cruz',
  'SUNY New Paltz',
  'Columbia University',
  'California State University, Los Angeles',
  'California State University - Sacramento',
  'University of North Carolina at Chapel Hill',
  'Ben Gurion University Of The Negev',
  'California Polytechnic State University, San Luis Obispo',
  'Morehouse College',
  'Cosumnes River College',
  'Fisk University',
  'Pacific Lutheran University',
  'Santa Clara University',
  'Vellore Institute of Technology',
  'Brown University',
  'University of Geneva',
  'Franklin and Marshall College',
  'Florida Polytechnic University',
  'Montgomery College',
  "Mount St Mary's University",
  'Boston University',
  'San Jose State University',
  'Pomona College',
  'Kennesaw State University',
  'George Washington University',
  'BITS Pilani',
  'Wesleyan University',
  'University of Alabama in Huntsville',
  'A.P.J Abdul Kalam Technical university',
  'North Carolina A&T State University',
  'Kent State University',
  'VIT University India',
  'Netaji Subhas University Of Technology',
  'Missouri State University',
  'Portland State University',
  'AICTE',
  'Illinois Institute of Technology',
  'Charotar University of Science and Technology',
  'Make School',
  'Ljubljana University',
  'R.V College of Engineering',
  "Queen's University, Kingston",
  'Michigan State University',
  'McMaster University',
  'Raghu Engineering College, Jawaharlal Nehru Technological Univeristy, Kakinada',
  'Western Ontario',
  'REVA University',
  'Full Sail University',
  'Las Positas College',
  'University of Maryland, College Park',
  'Syracuse University',
  'Harvard University',
  'Vellore Institute of Technology, Vellore',
  'Tulane University',
  'Wellesley',
  'Fontbonne University',
  'Juniata College',
  'San Diego Mesa College',
  'Diablo Valley College',
  'New York University Abu Dhabi',
  'Parul University',
  'University of Western Ontario',
  'Broward College',
  'Université de Sherbrooke',
  'Queens College',
  'Troy University',
  'Concordia University',
  'Catholic University of America ',
  'Irvine Valley College',
  'University of Arkansas - Fayetteville',
  'Wayne State University',
  'University of Wisconsin–Madison',
  'Mt. San Antonio College',
  'Embry-Riddle Aeronautical University',
  'Oakland University',
  'University of Ottawa',
  'Georgia College &amp; State University',
  'Columbus State University',
  'University of Texas at Arlington',
  'Indira Gandhi Delhi Technical University For Women',
  'Valencia College',
  'Broward University ',
  'Swarthmore College',
  'The Catholic University of America',
  'Lehigh University',
  'University of Houston',
  'North Carolina A&T',
  'University of Pittsburgh',
  'Florida Gulf Coast University',
  'University of California, Riverside',
  'IIT Bombay',
  'The College at Brockport, SUNY',
  'Dickinson College',
  'Alabama A&M University',
  'Banasthali Vidyapith',
  'Pennsylvania State University',
  'University of Mumbai',
  'Queens College, City University of New York',
  'GGSIPU',
  'Case Western Reserve University',
  'AKTU',
  'Delhi Technological University',
  'University of Kentucky',
  'SRM IST, KTR',
  'CalPoly, San Luis Obispo',
  'University of Alabama at Birmingham',
  'Indira Gandhi Delhi Technical University for Women',
  'SGGSIE&T Nanded',
  'Bahria University, Islamabad',
  'TiE University',
  'Vrije Universiteit Amsterdam',
  'DePaul University',
  'Harrisburg University',
  'Azerbaijan State Oil and Industry University ',
  'Agnes Scott College',
  'Oxford University',
  'IIITL',
  'Southern Illinois University Edwardsville',
  'Bharati Vidyapeeth College of Engineering, Pune',
  'Universidade Federal de Viçosa',
  'Indiana University - Bloomington',
  'Jain University',
  'VIT, Vellore',
  'Bentley University Graduate School',
  'CUSAT',
  'Indian Institute of Technology Delhi',
  'Maharaja Sayajirao University Of Baroda',
  'Florida State University',
  'University of Wyoming',
  'Mahatma Gandhi Institute of Technology',
  'Washington State University',
  'Universidad Peruana de Ciencias Aplicadas',
  'Asia Pacific Univiersity ',
  'Panjab University',
  'Saint Louis University',
  'University of Maryland, Baltimore County',
  'Symbiosis Institute of Technology',
  'University of British Columbia',
  'VIT University',
  'Anna University',
  'SPPU',
  'Bennett University',
  'Symbiosis Institute of Technology',
  'McMaster University'];

const schools = rawSchools.map((current, index) => {
    return {
        key: index,
        text: current,
        value: current
    }
})


export default schools;
