import React, {Component} from 'react';
import Content from './components/Content'

class App extends Component {

    render() {
        return (<div className="App-container">
            <Content/>
        </div>);
    };
};

export default App;
